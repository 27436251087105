import { format } from "date-fns"
import React from "react"
import styled from "styled-components"

const Container = styled.div<{ $passed?: boolean }>`
  padding: 6px;
  ${({ $passed }) => ($passed ? `opacity: 0.6;` : "")}/* display: flex; */
  /* border-top: 1px solid #d7d7d7; */
  /* position: relative; */
  /* align-items: center; */
`
const DateContent = styled.div`
  font-weight: bold;
`
const Title = styled.p`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0.15em;
`
const Address = styled.p`
  color: #333333;
  margin: 0;
`

const Event = ({ title, date, dateEnd, address, passed }) => {
  return (
    <Container $passed={passed}>
      <DateContent>
        {format(new Date(date), "dd/MM/yyyy")} {dateEnd && `- ${format(new Date(dateEnd), "dd/MM/yyyy")}`}
      </DateContent>
      <Title>{title}</Title>
      <Address>{address}</Address>
    </Container>
  )
}

export default Event

import { graphql } from "gatsby"
import React from "react"

import { EMAIL_CONTACT } from "src/constants"
import Layout from "src/layout"

import Events from "../components/Events"

const Agenda = ({ data }) => {
  const creationEvents = data.allContentfulEvent.edges

  return (
    <Layout title="Agenda" active={3}>
      <div style={{ textAlign: "center" }}>
        <h1>Hinterland</h1>
        <p>
          Association Loi 1901
          <br />
          siret: 84176715500010
        </p>
        <p>
          7 place paul Vigné d&rsquo;Octon
          <br />
          34800 Octon
        </p>
        <a href={`mailto:${EMAIL_CONTACT}`}>
          <h3>{EMAIL_CONTACT}</h3>
        </a>
      </div>
      <div>
        <Events events={creationEvents} />
      </div>
    </Layout>
  )
}

export default Agenda

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulEvent(sort: { fields: date, order: ASC }) {
      edges {
        node {
          id
          date
          dateEnd
          address
          creation {
            title
          }
        }
      }
    }
  }
`

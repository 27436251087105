import React from "react"
import styled from "styled-components"

import Event from "./Event"

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;
`

const Events = ({ events }) => {
  const now = new Date()
  const upcoming = events.filter(({ node }) => new Date(node.dateEnd || node.date) >= now)
  const passed = events.filter(({ node }) => new Date(node.dateEnd || node.date) < now)

  upcoming.sort((a, b) => new Date(a.node.date).getTime() - new Date(b.node.date).getTime())
  passed.sort((a, b) => new Date(b.node.date).getTime() - new Date(a.node.date).getTime())

  return (
    <Container>
      <div style={{ marginTop: 50 }}>
        <h2>A venir</h2>
        {upcoming.map(({ node }) => (
          <Event key={node.id} title={node.creation ? node.creation.title : node.title} {...node} />
        ))}
      </div>
      <div style={{ marginTop: 100 }}>
        <div>
          <h2>Passés</h2>
          {passed.map(({ node }) => (
            <Event passed key={node.id} title={node.creation ? node.creation.title : node.title} {...node} />
          ))}
        </div>
      </div>
    </Container>
  )
}

export default Events
